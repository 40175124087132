// components/Footer.js
import React from 'react';
import './Footer.css'; // Assuming you have a separate CSS file for the footer

const Footer = () => {
  return (
    <footer className="site-footer">
<div className="footer-divider"></div>

    <div className="container">
        <div className="footer-column">
            <h3>À propos de nous</h3>
            <p>Bienvenue chez Monda IPTV, votre référence premium dans le domaine de l’IPTV. Depuis 2012, nous nous engageons à fournir des services de qualité exceptionnelle. Chez Monda IPTV, nous sommes bien plus qu’une société de vente, nous sommes votre partenaire de divertissement. Notre équipe dévouée assure un support technique de premier ordre, garantissant une expérience fluide et sans souci. Plongez dans l’univers de l’IPTV avec confiance, où la qualité et le service dépassent vos attentes.</p>
        </div>
        <div className="footer-column">
            <h3>Pages</h3>
            <ul>
            <li><a href="#politique">Politique de confidentialité</a></li>
              <li><a href="#conditions">CONDITIONS GÉNÉRALES DE VENTE</a></li>
              <li><a href="#conditions">MENTIONS LÉGALES</a></li>
              <li><a href="#conditions">POLITIQUE DE REMBOURSEMENT</a></li>
              <li><a href="#conditions">Politique de cookies (UE)</a></li>
              <li><a href="#conditions">DMCA</a></li>
</ul>
        </div>
        <div className="footer-column">
            <h3>Contactez nous</h3>
            <p>Email : contact@mondatv.com</p>
        </div>
    </div>

    <div className="footer-divider"></div>
    
    <div className="container">
        <p>Copyright © mondatv</p>
    </div>    </footer>
  );
};

export default Footer;
