import React, { useState, useEffect } from 'react';
import './Pricing.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

const Pricing = () => {
  const initialTime = { hours: 0, minutes: 49, seconds: 23 };

  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setTimeLeft(prevTime => {
        let { hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds--;
        } else if (minutes > 0) {
          seconds = 59;
          minutes--;
        } else if (hours > 0) {
          minutes = 59;
          seconds = 59;
          hours--;
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, []);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <>
      <section id="Pack" className="offers-section">
        <div className="container text-center">
        <h2 className="offers-headline">NOS PACKS</h2>
          <p className="offers-subheadline">Profitez Maintenant De 70% De Réduction Sur Nos Formules</p>
          <p className="offers-subheadline-2">Garantie de Remboursement de 30 jours</p>
          <div className="countdown-container">
            <div className="time-box">
              <span>{formatTime(timeLeft.hours)}</span>
              <p>Heures</p>
            </div>
            <div className="time-box">
              <span>{formatTime(timeLeft.minutes)}</span>
              <p>Minutes</p>
            </div>
            <div className="time-box">
              <span>{formatTime(timeLeft.seconds)}</span>
              <p>Secondes</p>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing-section">
        <div className="container">
          <div className="row">
            {/* Pricing Plans */}
            <div className="col-md-4 pricing-card">
  <div className="card">
    <div className="card-header">
      <strong>SOLO</strong>
    </div>
    <div className="card-body">
      <div className="card-price"><span>39€</span></div>
      <div className="plan-duration">Pack: <strong>12 Mois</strong></div>
      <ul className="features-list">
        {/* List items with FontAwesome icons */}
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 30 000 chaînes compris : Amazon prime ligue 1 & ligue 2, beIN, RMC, Canal+, OCS, Paramount+</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 160 000 films et séries compris : Netflix, HBO, Apple+, Disney+, Amazon prime</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> HD/FHD/4K</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Adultes</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> EPG & Replay</li>
        <li><FontAwesomeIcon icon={faDotCircle} />Assistance Technique et Support 24h/7</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Mise à jour régulières</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Compatible avec tous les appareils et applications</li>



        {/* ... other list items ... */}
      </ul>
      <div className="plan-action">
                    <a
                      href="https://monbebetoys.com/boite12mois/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn-choose"
                    >
                      ACHETER MAINTENANT
                    </a>
                  </div>
    </div>
  </div>
</div>


            {/* Repeat for other plans */}


            <div className="col-md-4 pricing-card">
  <div className="card">
    <div className="card-header">
      <strong>PRIME</strong>
    </div>
    <div className="card-body">
      <div className="card-price"><span>49€</span></div>
      <div className="plan-duration">Pack: <strong>24 Mois</strong></div>
      <ul className="features-list">
        {/* List items with FontAwesome icons */}
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 30 000 chaînes compris : Amazon prime ligue 1 & ligue 2, beIN, RMC, Canal+, OCS, Paramount+</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 160 000 films et séries compris : Netflix, HBO, Apple+, Disney+, Amazon prime</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> HD/FHD/4K</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Adultes</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> EPG & Replay</li>
        <li><FontAwesomeIcon icon={faDotCircle} />Assistance Technique et Support 24h/7</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Mise à jour régulières</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Compatible avec tous les appareils et applications</li>



        {/* ... other list items ... */}
      </ul>
      <div className="plan-action">
                    <a
                      href="https://monbebetoys.com/boite24mois/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn-choose"
                    >
                      ACHETER MAINTENANT
                    </a>
                  </div>
    </div>
  </div>
</div>

        {/* ... other list items ... */}


<div className="col-md-4 pricing-card">
  <div className="card">
    <div className="card-header">
      <strong>PRIME DUO</strong>
    </div>
    <div className="card-body">
      <div className="card-price"><span>79€</span></div>
      <div className="plan-duration">Pack: <strong>24 Mois/Deux écrans</strong></div>
      <ul className="features-list">
        {/* List items with FontAwesome icons */}
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 30 000 chaînes compris : Amazon prime ligue 1 & ligue 2, beIN, RMC, Canal+, OCS, Paramount+</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Plus 160 000 films et séries compris : Netflix, HBO, Apple+, Disney+, Amazon prime</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> HD/FHD/4K</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Adultes</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> EPG & Replay</li>
        <li><FontAwesomeIcon icon={faDotCircle} />Assistance Technique et Support 24h/7</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Mise à jour régulières</li>
        <li><FontAwesomeIcon icon={faDotCircle} /> Compatible avec tous les appareils et applications</li>



        {/* ... other list items ... */}
      </ul>
      <div className="plan-action">
                    <a
                      href="https://monbebetoys.com/boite24moisduo/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn-choose"
                    >
                      ACHETER MAINTENANT
                    </a>
                  </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
