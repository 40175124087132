import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './HeroSection.css';


const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-overlay"> {/* Overlay for better text visibility */}
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} className="text-center">
              <h1>Révolutionnez Votre Expérience Télévisuelle</h1> {/* Headline */}
              <p>Plongez dans le monde vibrant d'IPTVBE où chaque moment est une découverte. Profitez de films, séries, et plus de 30 000 chaînes internationales </p> {/* Subheadline */}
              <h2>Profitez de 70% de Réduction sur Tous Nos Forfaits !</h2>
              <Button className="btn-custom" href='#Pack'>Voir les Offres</Button> {/* Discovery Button */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default HeroSection;
