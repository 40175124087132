import React from 'react';
import './ImageGallery.css';

const ImageGallery = () => {
    const images = [
        '/img/ms/1.webp', 
        '/img/ms/2.webp',
        '/img/ms/3.webp',
        '/img/ms/4.webp',
        '/img/ms/5.webp',
        '/img/ms/6.webp',
        '/img/ms/8.webp',
        '/img/ms/9.webp',
        '/img/ms/10.webp',
        '/img/ms/11.webp',
        '/img/ms/12.webp',
        '/img/ms/14.webp',
        '/img/ms/15.webp',
        '/img/ms/16.webp',
        '/img/ms/1.jpg',
        '/img/ms/2.jpg',
        '/img/ms/13.jpg',
        '/img/ms/14.jpg',
        '/img/ms/15.jpg',
    ];

    return (
        <section className='Gallery'>
        <div className="image-gallery-section">
            <div className="text-center">
                <h2>Entrez dans le Monde du Cinéma et des Séries Illimités!</h2>
                <p>Avec plus de 160 000 films et séries, l'aventure ne s'arrête jamais</p>
            </div>
            <div className="image-gallery">
                <div className="scrolling-wrapper">
                    {images.map((image, index) => (
                        <img key={index} src={image} alt={`Poster ${index}`} />
                    ))}
                </div>
            </div>
        </div>
        </section>
    );
};

export default ImageGallery;
