import React from 'react';
import './WhyUs.css'; // Make sure the path is correct based on your file structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faHeadset, faThumbsUp, faServer,  } from '@fortawesome/free-solid-svg-icons';

const WhyUs = () => {
  return (
    <section className="why-choose-us">
      <div className="container">
        <h2 className="section-title text-center">POURQUOI NOUS CHOISIR ?</h2>
        <div className="row">
          <div className="col-md-4 feature-bubble">
            <FontAwesomeIcon icon={faCogs} size="2x" />
            <h3>Installation Instantanée</h3>
            <p>Vous pouvez facilement configurer et installer votre abonnement IPTV sur votre appareil préféré...</p>
          </div>
          <div className="col-md-4 feature-bubble">
            <FontAwesomeIcon icon={faHeadset} size="2x" />
            <h3>Support technique 24h/7</h3>
            <p>Profitez d'un support client réactif et disponible à tout moment. Notre équipe est là pour vous assister 24h/24, 7j/7, garantissant une aide immédiate pour toutes vos questions ou préoccupations.</p>
          </div>
          <div className="col-md-4 feature-bubble">
            <FontAwesomeIcon icon={faThumbsUp}  size="2x" />
            <h3>Satisfait ou remboursé</h3>
            <p>Votre satisfaction est notre priorité. Si vous n'êtes pas entièrement satisfait de votre abonnement, nous nous engageons à vous rembourser intégralement dans les 30 jours suivant votre achat.</p>
          </div>
          <div className="col-md-4 feature-bubble">
            <FontAwesomeIcon icon={faServer}  size="2x" />
            <h3>Serveur stable</h3>
            <p>Bénéficiez d'une expérience de visionnage ininterrompue grâce à nos serveurs hautement stables et fiables. Profitez de vos programmes favoris sans coupures ni délais, assurant une qualité de service optimale.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
