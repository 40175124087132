import React from 'react';
import './LogoCarousel.css'; // Make sure this CSS file is properly linked

const LogoCarousel = () => {
    const logos = ['/img/tv/1.png', '/img/tv/2.png', '/img/tv/3.png','/img/tv/26.png','/img/tv/4.png','/img/tv/5.png','/img/tv/6.png','/img/tv/7.png','/img/tv/8.png','/img/tv/9.png','/img/tv/33.png', '/img/tv/34.png', '/img/tv/35.png']; // Replace with your actual logos
    const logos2 = ['/img/tv/10.png','/img/tv/11.png','/img/tv/12.png','/img/tv/13.png','/img/tv/14.png','/img/tv/15.png', '/img/tv/16.png','/img/tv/17.png','/img/tv/18.png','/img/tv/19.png','/img/tv/20.png', '/img/tv/36.png', '/img/tv/39.png']; // Replace with your actual logos
    const logos3 = ['/img/tv/21.png','/img/tv/22.webp','/img/tv/23.webp','/img/tv/24.webp','/img/tv/25.webp','/img/tv/27.png', '/img/tv/28.png', '/img/tv/29.png', '/img/tv/30.png', '/img/tv/40.png','/img/tv/41.png', '/img/tv/43.png']; // Replace with your actual logos

    return (
        <div className="galleries-panel">
            <div className="gallery-line">
                {logos.map((logo, index) => (
                    <div key={index} className="logo-background">
                        <img src={logo} alt={`Logo ${index + 1}`} />
                    </div>
                ))}
            </div>
            <div className="gallery-line reverse">
  {logos2.map((logo2, index) => (
    <div key={index} className="logo-background">
      <img src={logo2} alt={`Logo ${index + 1}`} />
    </div>
  ))}
</div>
            <div className="gallery-line">
                {logos3.map((logo3, index) => (
                    <div key={index} className="logo-background">
                        <img src={logo3} alt={`Logo ${index + 1}`} />
                    </div>
                ))}
            </div>
            {/* Repeat for additional gallery lines */}
        </div>
    );
};

export default LogoCarousel;
