import React, { useState, useEffect } from 'react';
import './ChannelList.css';

const ChannelList = () => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [channels, setChannels] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch countries for the dropdown
    useEffect(() => {
        fetch('http://localhost/backend/fetchChannels.php?listCountries=true')
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            })
            .catch(error => console.error('Erreur lors de la récupération des pays:', error));
    }, []);

    // Fetch channels based on selected country or search term
    useEffect(() => {
        const url = searchTerm
            ? `http://localhost/backend/fetchChannels.php?search=${searchTerm}`
            : `http://localhost/backend/fetchChannels.php?country=${selectedCountry}`;

        fetch(url)
            .then(response => response.json())
            .then(data => setChannels(data))
            .catch(error => console.error('Erreur lors de la récupération des chaînes:', error));
    }, [selectedCountry, searchTerm]);

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        setSearchTerm(''); // Clear search when a new country is selected
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setSelectedCountry(''); // Clear country selection when searching
    };

    return (
        <div id='channels' className="channel-list-section">
            <div className="selection-bar">
                <select onChange={handleCountryChange} value={selectedCountry}>
                    <option value="">Sélectionnez un pays</option>
                    {countries.map((country, index) => (
                        <option key={index} value={country.country}>{country.country}</option>
                    ))}
                </select>
                <input 
                    type="text" 
                    placeholder="Rechercher une chaîne" 
                    onChange={handleSearchChange} 
                    value={searchTerm} 
                />
            </div>
            <div className="channel-table">
                {channels.length > 0 ? channels.map((channel, index) => (
                    <div key={index} className="channel">
                        <span className="channel-icon">🟢</span>
                        <span className="channel-name">{channel.channel_name} - {channel.country}</span>
                    </div>
                )) : <p>Veuillez sélectionner un pays ou rechercher une chaîne.</p>}
            </div>
        </div>
    );
};

export default ChannelList;
