import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Navbar.css';
import Logo from '../../img/logo.png';

const NavigationBar = () => {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container>
        <Navbar.Brand href="#home">
          <img src={Logo} alt="Logo" style={{ maxHeight: '50px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home">Accueil</Nav.Link>
            <Nav.Link href="#channels">Liste des Chaines</Nav.Link>
            <Nav.Link href="#clients">Avis Client</Nav.Link>
          </Nav>
          <Button href="#Pack" variant="outline-primary" className="btn-special">Nos Offres</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
