import React from 'react';
import './Reviews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination,   } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Reviews = () => {
    return (
      <section id='clients' className="reviews-section">
        <div className="container">
          <h2>UNE EXPÉRIENCE IPTV CINQ ÉTOILES</h2>
          <p>Découvrez pourquoi les gens aiment notre service IPTV rapide, simple et facile à utiliser pour tous les appareils.</p>
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              // when window width is <= 639px
              639: {
                slidesPerView: 1,
                spaceBetween: 10
              }
            }}
          >
          {/* Repeat SwiperSlide for each review */}
          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/h1.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>J'ai souscrit à l'abonnement à vie et je suis bluffé par la qualité ! Plus de 30 000 chaînes en HD et 4K, c'est incroyable. Je recommande vivement Monda tv.</p>
                <cite>Marc Dupont</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/f1.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>En tant que fan de séries, l'accès à 160 000 films et séries est un vrai paradis. La technologie AntiFreeze fait toute la différence. Merci, Monda tv </p>
                <cite>Sophie Lemaire</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/f2.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>Le support client 24/7 est exceptionnel. J'ai eu un petit souci de configuration, résolu en quelques minutes. Service client au top !</p>
                <cite>Émilie Girard</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/h2.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>Je suis épaté par la variété des chaînes internationales. J'ai accès à tout ce que je veux voir, et la qualité d'image est impressionnante.</p>
                <cite>Thomas Leroy</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/h3.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>J'ai comparé plusieurs services IPTV,  se démarque nettement par sa qualité et son prix. Leur offre à vie est inégalée.</p>
                <cite>Alexandre Petit</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/f3.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>Je recommande Monda tv pour leur qualité de service et leur offre incroyable. C'est le choix idéal pour un cinéphile comme moi.</p>
                <cite>Clara Martin</cite>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="review-bubble">
              <div className="client-image">
                <img src="/img/pf/h4.jpg" alt="Client Name" />
              </div>
              <div className="review-content">
                <div className="review-stars">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </div>
                <p>L'expérience utilisateur est fluide et intuitive. Je trouve toujours quelque chose d'intéressant à regarder. Bravo à l'équipe</p>
                <cite>Nicolas Richard</cite>
              </div>
            </div>
          </SwiperSlide>
          {/* Repeat SwiperSlide for more reviews */}
          {/* ... */}
        </Swiper>
      </div>
    </section>
  );
};

export default Reviews;
