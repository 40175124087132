import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './components/HeroSection/HeroSection.css'; 
import NavigationBar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import ImageGallery from './components/ImageGallery/ImageGallery';
import ChannelList from './components/ChannelList/ChannelList';
import LogoCarousel from './components/LogoCarousel/LogoCarousel';
import Steps from './components/Steps/Steps';
import Pricing from './components/Pricing/Pricing';
import WhyUs from './components/WhyUs/WhyUs';
import Reviews from './components/Reviews/Reviews';
import Footer from './components/Footer/Footer';
import './App.css'; // Ensure this CSS file is properly linked

function App() {
    return (
        <div className="App">
            <NavigationBar />
            <HeroSection />
            <ImageGallery />


            <div className="tv-section">
                <div className="header">
                    <h2>Le Meilleur de la Télévision Mondiale, à Votre Disposition!</h2>
                    <p>Découvrez un monde de choix avec plus de 30 000 chaînes, incluant des chaînes nationales, internationales et cryptées</p>
                </div>
                <div className="content-row">
                    <ChannelList />
                    <LogoCarousel />
                  
                    
                </div>
            </div>
            <WhyUs />
            <Pricing />
            <Steps />
            <Reviews />
            <Footer/>

            {/* Other components */}
        </div>
    );
}

export default App;
