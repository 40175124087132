import React from 'react';
import './Steps.css';

const Steps = () => {
  return (
    <section className="steps-section">
      <div className="container text-center">
        <h2 className="headline">COMMENT ÇA MARCHE ?</h2>
        <div className="row justify-content-center">
          <div className="col-md-4 step-bubble">
            <h3>01</h3>
            <p className="step-subheadline">COMMANDER</p>
            <p>Après avoir validé votre commande, vous recevrez immédiatement vos liens et identifiants sur la page de confirmation ainsi que dans votre boîte email.</p>
          </div>
          <div className="col-md-4 step-bubble">
            <h3>02</h3>
            <p className="step-subheadline">INSTALLER</p>
            <p>Suivez les instructions d'installation pour chaque appareil que vous souhaitez utilisez.</p>
          </div>
          <div className="col-md-4 step-bubble">
            <h3>03</h3>
            <p className="step-subheadline">REGARDER</p>
            <p>Profitez de votre passion sur votre télévision, smartphone ou ordinateur.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
